import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';

const ModalHeader = ({
  children,
  onClose,
}: {
  children: React.ReactNode;
  onClose?: () => void;
}) => {
  return (
    <HeaderStyle>
      <TitleStyle>{children}</TitleStyle>
      <ChildrenStyle onClick={() => typeof onClose === 'function' && onClose()}>
        <CloseIcon></CloseIcon>
      </ChildrenStyle>
    </HeaderStyle>
  );
};

const HeaderStyle = styled.div`
  display: flex;
  flex-direction: row;
  height: 80px;
  justify-content: space-around;
  align-items: center;
`;

const TitleStyle = styled.div`
  display: flex;
`;

const ChildrenStyle = styled.div`
  position: absolute;
  right: 20px;
  margin: 5px 0 0 0;
  cursor: pointer;
`;

export default ModalHeader;
