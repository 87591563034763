import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import MobileHeader from 'components/commons/MobileHeader';
import CnServiceTerms from 'features/mainFeatures/terms/containers/TransUseTerms/CnServiceTerms';
import CnPrivateTerms from 'features/mainFeatures/terms/containers/TransUseTerms/CnPrivateTerms';
import CnLocationTerms from 'features/mainFeatures/terms/containers/TransUseTerms/CnLocationTerms';
import TermsBtnStyle from 'features/mainFeatures/terms/components/TermsBtnStyle';
import TermsBtnLabelStyle from 'features/mainFeatures/terms/components/TermsBtnLabelStyle';

const CnUseTermsContainer = () => {
  const { t } = useTranslation();
  const [selectedTerms, setSelectedTerms] = useState(1);

  const terms = [
    { id: 1, title: t('servicePolicy'), content: <CnServiceTerms /> },
    { id: 2, title: t('privacyPolicy'), content: <CnPrivateTerms /> },
    { id: 3, title: t('locationServiceTermsConditions'), content: <CnLocationTerms /> },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [selectedTerms]);

  return (
    <TermWrapper>
      <MobileHeader title="使用条款" />
      <TermsTitle>{t('termsOfService')}</TermsTitle>
      <TermsBtnContainer>
        {terms.map(({ id, title }) => (
          <TermsBtnWrapper key={id}>
            <TermsBtnStyle
              id={title}
              key={id}
              type="radio"
              name="termsModal"
              onClick={() => {
                setSelectedTerms(id);
              }}
              defaultChecked={id === 1}
            />
            <TermsBtnLabelStyle htmlFor={title} key={title}>
              {title}
            </TermsBtnLabelStyle>
          </TermsBtnWrapper>
        ))}
      </TermsBtnContainer>
      <TermsContent id="terms_content">{terms[selectedTerms - 1].content}</TermsContent>
    </TermWrapper>
  );
};

const TermWrapper = styled.div`
  @media ${(props) => props.theme.media.desk} {
    width: 600px;
  }
  @media ${(props) => props.theme.media.mobile} {
    /* width: ; */
  }
`;
const TermsTitle = styled.div`
  font-size: 30px;
  text-align: center;
  padding-bottom: 32px;
  padding-top: 32px;
  @media ${(props) => props.theme.media.mobile} {
    display: none;
  }
`;

const TermsBtnContainer = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 0 0.8em;
  margin-top: 32px;
`;

const TermsContent = styled.div`
  /* margin-top: 32px; */
  height: 100%;
  /* overflow-y: scroll; */
`;

const TermsBtnWrapper = styled.div`
  width: 100%;
  display: contents;
`;

export default CnUseTermsContainer;
