import React from 'react';
import styled from 'styled-components';

import PaddingLayout from 'features/mainFeatures/terms/components/PaddingLayout';
import TermsSection from 'features/mainFeatures/terms/components/TermsSection';

const MarketingTerms = () => {
  return (
    <PaddingLayout>
      <TermsSection>
        <p>
          "{process.env.REACT_APP_SERVEICE_NAME}(이하, '서비스'라 합니다.)"의 서비스 제공 및 이용과
          관련하여 ㈜엔비에이치디(이하, '회사'라 합니다)은 "정보통신망 이용촉진 및 정보보호 등에
          관한 법률" 및 “개인정보보호법” 등 관련 법령상의 개인정보 보호 규정을 준수합니다.
        </p>
      </TermsSection>
      <TermsSection>
        <ol>
          <li>
            "{process.env.REACT_APP_SERVEICE_NAME}"에서는 이용자가 수집 및 이용에 동의한 개인정보를
            활용하여, 전자적 전송 매체(SMS/MMS/e-mail/App Push. 등 다양한 전송 매체)를 통하여,
            {process.env.REACT_APP_SERVEICE_NAME} 및 제3자의 상품 또는 서비스에 대한 개인 맞춤형
            광고 정보를 전송할 수 있습니다.
          </li>
          <li>
            이용자가 본 수신 동의를 철회하고자 할 경우 설정({process.env.REACT_APP_SERVEICE_NAME}{' '}
            {'>'} 로그인 {'>'} 설정 {'>'} 마케팅동의 화면)을 통하여 수신 동의 철회 요청을 할 수
            있습니다.
          </li>
          <li>
            제공서비스 (선택적 기능)
            <ol type="a">
              <li>이용대상 : 성별, 나이를 기입하고, ‘마케팅 활용 및 수신’에 동의한 자</li>
              <li>제공서비스 : {process.env.REACT_APP_SERVEICE_NAME} 탬플릿</li>
              <li>이용기간 : ‘마케팅 동의’시점 ~ ‘해제’시까지</li>
              <li>기 타 : 마케팅 동의 조건으로 이동가능한 ‘탬플릿’은 ‘동의해제’ 순간시</li>
            </ol>
          </li>
          <li>이용항목 / 이용목적 / 보유 및 이용기간</li>
        </ol>
        <Table>
          <thead>
            <tr>
              <th>이용항록</th>
              <th>이용목적</th>
              <th>보유 및 이용기간</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Email, 성별, 나이, 푸시 ID</td>
              <td>
                마케팅 광고에 활용 <br /> 1) "서비스"의 개발 및 개선, "서비스" 관련 문의 등에 대한
                개인 맞춤 상담 &#x0002E; 정보 제공 <br /> 2) 신규 서비스나 이벤트, 제 3자의 상품
                또는 "서비스" 관련 개인 맞춤형 광고 &#x0002E; 정보 전송
              </td>
              <td>
                - 별도 동의 철회시까지 <br /> - 단, 관련 법력의 규정에 따라 보존할 필요가 있을 경우,
                해당 기간까지 보존함
              </td>
            </tr>
          </tbody>
        </Table>
      </TermsSection>
      <TermsSection>
        <p>
          ※ 본 동의는 "{process.env.REACT_APP_SERVEICE_NAME}"의 선택적 기능 제공을 위한 개인정보
          수집/이용에 대한 동의로서, 동의하지 않더라도 "{process.env.REACT_APP_SERVEICE_NAME}"
          이용이 가능합니다.
        </p>
        <p>
          ※ 법령에 따른 개인정보의 수집/이용, 계약의 이행/편의 증진을 위한 개인정보 처리위탁 및
          개인정보 처리와 관련된 일반 사항은 “㈜엔비에이치디”의 개인정보처리방침에 따릅니다.
        </p>
      </TermsSection>
      <TermsSection>
        <p>(시행일) 이 약관은 2022년 6월 15일부터 시행합니다.</p>
      </TermsSection>
    </PaddingLayout>
  );
};

const Table = styled.table`
  width: 100%;
  border: 1px solid #444444;
  border-collapse: collapse;

  > tr,
  th,
  td {
    border: 1px solid #444444;
    padding: 10px;
    font-size: 12px;
  }
`;

export default MarketingTerms;
