import styled from 'styled-components';

/**
 * @description
 */
const TermsBtnStyle = styled.input`
  display: none;
  &:checked + label {
    background: #3e64ea;
    color: white;
  }
`;

export default TermsBtnStyle;
