import React from 'react';
import UseTermsContainer from 'features/mainFeatures/terms/containers/UseTermsContainer';
import PrivateTerms from 'features/mainFeatures/terms/containers/PrivateTerms';
import TermsPage from 'features/mainFeatures/terms/components/TermsPage';

const PrivateTermsPage = () => {
  return (
    <TermsPage>
      <UseTermsContainer />
      <PrivateTerms />
    </TermsPage>
  );
};

export default PrivateTermsPage;
