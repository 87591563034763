import styled from 'styled-components';

/**
 * @description
 */
const TermsTitle = styled.span`
  font-weight: 500;
  font-size: 16px;
  color: #222222;
`;

export default TermsTitle;
