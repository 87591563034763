import styled from "styled-components";

/**
 * @description
 */
const TopWrapperLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.4em 1em;
  height: 2.5em;
`;

export default TopWrapperLeft;
