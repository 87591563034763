import styled from 'styled-components';

/**
 * @description
 */
const TermsSection = styled.div`
  padding: 0.5em;
  > p,
  > ol {
    color: #666666;
    font-weight: 400;
  }
  > ol,
  > ol > li > ol {
    padding-left: 1.5em;
  }
`;

export default TermsSection;
