import styled from 'styled-components';

const SectionBox = styled.button`
  border-radius: 5px;
  background-color: #fdfdfd;
  width: 100%;
  padding: 0;
  border: 1px solid #eee;
`;

const SectionTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  height: 40px;
  display: flex;
  align-items: center;
`;

export { SectionBox, SectionTitle };
