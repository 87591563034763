import React from 'react';
import { FullScreenStyle } from 'resources/styles/common/styles';
import MarketingTermsContainer from 'features/mainFeatures/terms/containers/MarketingTermsContainer';
import styled from 'styled-components';

const MarketingTermsPage = () => {
  return (
    <TermsPage>
      <MarketingTermsContainer />
    </TermsPage>
  );
};

/**
 * @description
 */
const TermsPage = styled(FullScreenStyle)`
  padding-top: 4em;
  background: white;
  justify-content: center;
  display: flex;
  overflow: auto;
`;

export default MarketingTermsPage;
