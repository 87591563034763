import React from 'react';
import { CircularProgress } from '@mui/material';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import styled from 'styled-components';

const Progress = observer(() => {
  const { uiStore } = useStore();

  return (
    <>
      {uiStore.progress.progressShow && (
        <ProgressContainerStyle on={uiStore.progress.progressShow}>
          <CircularProgress style={{ color: '#fff' }} />
        </ProgressContainerStyle>
      )}
    </>
  );
});

const ProgressContainerStyle = styled.div<{ on: boolean }>`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.2);
  transition: all 0.4s ease-out;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Progress;
