import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

interface MenuItems {
  title: string;
  onClick: () => void;
}

interface MenuPopupDto {
  open: boolean;
  menuListLanguage: MenuItems[];
  handleClose: () => void;
}

const GlobalMenuPopup = ({ open, menuListLanguage, handleClose }: MenuPopupDto) => {
  return (
    <Menu
      id="account-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          backgroundColor: '#ffffffde',
          paddingTop: '0px !important',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          borderRadius: '16px',
          marginTop: '50px',
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
    >
      {menuListLanguage.map((item: MenuItems, i: number) => (
        <MenuItem
          sx={{
            width: 240,
            borderTop: `${i === 0 ? '' : '2px solid rgb(229, 229, 229, 0.895)'}`,
            height: '56px',
            fontWeight: '600',
            textAlign: 'center',
            justifyContent: 'space-evenly',
          }}
          key={i}
          onClick={item.onClick}
        >
          {item.title}
        </MenuItem>
      ))}
    </Menu>
  );
};

export default GlobalMenuPopup;
