import PaddingLayout from 'features/mainFeatures/terms/components/PaddingLayout';
import TermsSection from 'features/mainFeatures/terms/components/TermsSection';
import TermsTitle from 'features/mainFeatures/terms/components/TermsTitle';
import React from 'react';

const EnServiceTerms = () => {
  return (
    <PaddingLayout>
      <TermsSection>
        <TermsTitle>미국 서비스 약관</TermsTitle>
        <ol>
          <li>서비스</li>
        </ol>
      </TermsSection>
    </PaddingLayout>
  );
};

export default EnServiceTerms;
