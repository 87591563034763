import styled from 'styled-components';

/** flex-direction: row 형태의 기본 (각 margin을 props로 받을 수 있다.) */
export const CommonRowStyle = styled.div<{
  padding?: number;
  margin?: number;
  marginTop?: number;
  marginBottom?: number;
  marginRight?: number;
  marginLeft?: number;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${(props) => (props?.padding ? props.padding : 0)}px;
  margin: ${(props) => (props?.margin ? props.margin : 0)}px;
  margin: ${(props) => (props?.marginTop ? props?.marginTop : 0)}px
    ${(props) => (props?.marginRight ? props?.marginRight : 0)}px
    ${(props) => (props?.marginBottom ? props?.marginBottom : 0)}px
    ${(props) => (props?.marginLeft ? props?.marginLeft : 0)}px;
`;

/** flex-direction: row & justify-content: space-between 형태의 기본 (각 margin을 props로 받을 수 있다.) */
export const CommonBetweenRowStyle = styled.div<{
  padding?: number;
  margin?: number;
  marginTop?: number;
  marginBottom?: number;
  marginRight?: number;
  marginLeft?: number;
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => (props?.padding ? props.padding : 0)}px;
  margin: ${(props) => (props?.margin ? props.margin : 0)}px;
  margin: ${(props) => (props?.marginTop ? props?.marginTop : 0)}px
    ${(props) => (props?.marginRight ? props?.marginRight : 0)}px
    ${(props) => (props?.marginBottom ? props?.marginBottom : 0)}px
    ${(props) => (props?.marginLeft ? props?.marginLeft : 0)}px;
`;

export const CommonCenterAllStyle = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

export const CommonButtonStyle = styled.button`
  width: 100%;
  height: 50px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 0;
  background-color: ${(props) => props.theme.colors.pointColor};
  cursor: pointer;
`;

export const FullScreenStyle = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  left: calc(-50vw + 50%);
`;
