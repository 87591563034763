import React from 'react';
import UseTermsContainer from 'features/mainFeatures/terms/containers/UseTermsContainer';
import LocationTerms from 'features/mainFeatures/terms/containers/LocationTerms';
import TermsPage from 'features/mainFeatures/terms/components/TermsPage';

const LocationTermsPage = () => {
  return (
    <TermsPage>
      <UseTermsContainer />
      <LocationTerms />
    </TermsPage>
  );
};

export default LocationTermsPage;
