import styled from 'styled-components';

/**
 * @description
 */
const TopWrapper = styled.div`
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.05);
  background: white;
  @media ${(props) => props.theme.media.desk} {
    display: none;
  }
`;

export default TopWrapper;
