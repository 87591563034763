import React from 'react';
import { FullScreenStyle } from 'resources/styles/common/styles';
import JpUseTermsContainer from 'features/mainFeatures/terms/containers//JpUseTermsContainer';
import styled from 'styled-components';

const JpUseTermsPage = () => {
  return (
    <TermsPage>
      <JpUseTermsContainer />
    </TermsPage>
  );
};

/**
 * @description
 */
const TermsPage = styled(FullScreenStyle)`
  background: white;
  justify-content: center;
  display: flex;
`;

export default JpUseTermsPage;
