import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { SizeInfo } from 'types/CommonTypes';

const IconButton = ({
  image,
  size,
  selected = true,
  children,
  containerStyle,
  disabled,
  onClick,
}: {
  image?: string;
  size?: SizeInfo;
  selected?: boolean;
  containerStyle?: CSSProperties;
  titleStyle?: CSSProperties;
  disabled?: boolean;
  children?: any;
  onClick?: (e: any) => void;
  onBadgeClick?: () => void;
}) => {
  const _style = { ...containerStyle };
  return (
    <IconButtonStyle
      disabled={disabled}
      onClick={(e) => {
        typeof onClick == 'function' && disabled !== true && onClick(e);
      }}
      style={_style}
    >
      <div style={{ display: 'flex', marginRight: 5 }}>
        <ImageStyle
          disabled={disabled}
          src={image}
          style={{
            width: '100%',
          }}
          alt=""
        />
      </div>
      <ChildrenStyle disabled={disabled}>{children}</ChildrenStyle>
    </IconButtonStyle>
  );
};

const IconButtonStyle = styled.div<{ disabled: boolean | undefined }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 5px 16px;
  flex-wrap: wrap;
  border-radius: 25px;
  font-size: 12px;
  border: 3px solid #efefef;
  @media ${(props) => props.theme.media.mobile} {
    width: 50px;
    height: 50px;
  }
  cursor: ${(props) => (props.disabled === true ? 'default' : 'pointer')};
  transition: all 0.2s ease-in-out;

  &:hover {
    border: ${(props) => (props.disabled === true ? '3px solid efefef' : '3px solid transparent')};
    transition: all 0.2s ease-in-out;
  }
`;

const ImageStyle = styled.img<{ disabled: boolean | undefined }>`
  width: 100%;
  text-align: center;

  opacity: ${(props) => (props.disabled === true ? 0.5 : 1)};
`;

const ChildrenStyle = styled.div<{ disabled: boolean | undefined }>`
  color: #fff;
  opacity: ${(props) => (props.disabled === true ? 0.5 : 1)};
`;
export default IconButton;
