// import axios from 'axios';
// import { KAKAO_REST_API_KEY } from 'stores/AppStore';
import Geocode from 'react-geocode';
import { GOOGLE_MAP_API_KEY } from 'stores/AppStore';

let lng = localStorage.getItem('i18nextLng');
if (typeof GOOGLE_MAP_API_KEY !== 'undefined') {
  if (lng === null) {
    lng = 'en';
  }
  Geocode.setApiKey(GOOGLE_MAP_API_KEY);
  Geocode.setLanguage(lng);
  Geocode.setRegion(lng);
  Geocode.enableDebug();
}

// const getGeoCoder = async (keyword: any) => {
//   return await axios
//     .get(
//       `https://dapi.kakao.com/v2/local/search/address.json?analyze_type=exact&query=${keyword}`,
//       {
//         headers: {
//           Authorization: `KakaoAK ${KAKAO_REST_API_KEY}`,
//         },
//       },
//     )
//     .then((res) => {
//       const locations = res.data.documents;
//       return locations;
//       // setLocationObj({
//       //   si: location.address.region_1depth_name,
//       //   gu: location.address.region_2depth_name,
//       //   dong: location.address.region_3depth_name,
//       //   locationX: location.address.x,
//       //   locationY: location.address.y,
//       // });
//     });
// };

const getGeoCodeByAddress = async (keyword: string) => {
  return Geocode.fromAddress(keyword)
    .then((response) => {
      return response.results;
    })
    .catch((err) => {
      return null;
    });
};

export default getGeoCodeByAddress;
