import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import GlobalMenuPopup from 'components/commons/GlobalMenuPopup';
import useScreenType from 'libs/hooks/useScreenType';
import Button from '@mui/material/Button';
import styled from 'styled-components';

const GnbLanguage = observer(() => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const screenType = useScreenType();
  const { i18n } = useTranslation();
  const open = Boolean(anchorEl);
  const menuListLanguage = [
    {
      title: '한글',
      onClick: () => {
        handleClickChangeLanguage('ko');
      },
    },
    {
      title: 'Eng',
      onClick: () => {
        handleClickChangeLanguage('en');
      },
    },
    {
      title: '日本語',
      onClick: () => {
        handleClickChangeLanguage('jp');
      },
    },
    // {
    //   title: 'cn',
    //   onClick: () => {
    //     handleClickChangeLanguage('cn');
    //   },
    // },
    // {
    //   title: 'sp',
    //   onClick: () => {
    //     handleClickChangeLanguage('sp');
    //   },
    // },
  ];

  const handleClickOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClickChangeLanguage = (target: string) => {
    i18n.changeLanguage(target);
    localStorage.setItem('language', target);
    document.documentElement.lang = target;
  };

  return (
    <>
      {screenType === 'window' && (
        <Button onClick={handleClickOpenMenu}>
          <GlobalIconStyle src="/image/global.png" />
        </Button>
      )}
      <GlobalMenuPopup
        open={open}
        menuListLanguage={menuListLanguage}
        handleClose={handleClickCloseMenu}
      />
    </>
  );
});

const GlobalIconStyle = styled.img`
  width: 30px;
  height: 30px;
`;

export default GnbLanguage;
