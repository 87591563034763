import PaddingLayout from 'features/mainFeatures/terms/components/PaddingLayout';
import TermsSection from 'features/mainFeatures/terms/components/TermsSection';
import TermsTitle from 'features/mainFeatures/terms/components/TermsTitle';
import React from 'react';

const EnPrivateTerms = () => {
  return (
    <PaddingLayout>
      <TermsSection>
        <TermsTitle>미국 개인정보 약관</TermsTitle>
        <ol>
          <li>개인정보</li>
        </ol>
      </TermsSection>
    </PaddingLayout>
  );
};

export default EnPrivateTerms;
