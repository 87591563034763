/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  CSSProperties,
  ForwardedRef,
  forwardRef,
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useLayoutEffect,
} from 'react';

import { validityCheck, validityCustomCheck } from 'controls/validationHelper';
import { InputFormat, ValidationType } from 'types/CommonTypes';
import { useTranslation } from 'react-i18next';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import styled from 'styled-components';

interface InputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onBlur'> {
  /**
   * @description Validation을 사용하기 위해서 id가 선언되어야 함.
   * */
  id?: string;
  label?: any;
  value?: any;

  /**
   * @description Validation을 사용하기 위해서 format이 선언되어야 함.
   * @description  선언되지 않을경우 required만 검사함
   */

  format?: InputFormat;
  /**
   * @description Validation을 사용하기 위해서 required 선언되어야 함.
   * */
  required?: boolean;

  /*
   * @description Custom Expression 및 메시지
   */
  customValidation?: ValidationType;

  containerStyle?: CSSProperties;
  labelStyle?: CSSProperties;
  boxStyle?: CSSProperties;
  messageLocation?: 'top' | 'bottom';
  onChangeValue?: (value: string) => void;
  onBlur?: (isFieldValid: boolean | undefined) => void;
}
/**
 * @description 이미지 선택부터 크롭까지 제공한다.
 * @returns
 */
const Input = observer(
  forwardRef(
    (
      {
        id,
        label,
        onChangeValue,
        required,
        customValidation,
        format,
        containerStyle,
        boxStyle,
        labelStyle,
        messageLocation = 'bottom',
        onBlur,
        ...rest
      }: InputProps,
      ref,
    ) => {
      const { t } = useTranslation();
      const [validMessage, setValidMessage] = useState<string>();
      const { formValidationStore } = useStore();
      const isFieldValid = useRef<boolean>();
      const inputRef = useRef<any>(null);
      // useImperativeHandle(ref, () => ({
      //   handleFocus,
      // }));

      // eslint-disable-next-line no-underscore-dangle
      const _containerStyle: React.CSSProperties = {
        borderRadius: 5,
        display: 'flex',
        height: 45,
        ...containerStyle,
      };

      const handleFocus = () => {
        inputRef.current.focus();
      };
      /**
       * @description 입력된 값이 지정된 타입의 허용된 범위내에 있는지 체크한다.
       * @description Validation을 구현하는 하위 컨트롤은 반드시 구현하여야 한다.
       * @param {Object} event
       */
      const handleCheckValidate = (value?: any) => {
        if (value === null) return;
        if (required && typeof id !== 'undefined') {
          if (typeof value === 'undefined' || value?.length === 0) {
            setValidMessage(t('requiredItem'));
            formValidationStore.handleCheckValid(id, false);
          } else {
            if (typeof format !== 'undefined') {
              isFieldValid.current = validityCheck(value, format);
              formValidationStore.handleCheckValid(id, isFieldValid.current);
              if (isFieldValid.current === true) {
                setValidMessage('');
              } else {
                setValidMessage(formValidationStore.messages[format]);
              }
            } else if (typeof customValidation !== 'undefined') {
              formValidationStore.handleCheckValid(
                id,
                validityCustomCheck(value, customValidation),
              );
              setValidMessage(customValidation.message);
            } else {
              formValidationStore.handleCheckValid(id, true);
              setValidMessage('');
            }
          }
        }
      };

      useEffect(() => {
        if (typeof customValidation !== 'undefined') {
          setValidMessage(customValidation.message);
        } else {
          setValidMessage('');
        }
      }, [customValidation]);

      useEffect(() => {
        if (rest.disabled === true) {
          setValidMessage('');
        }
      }, [rest.disabled]);
      /**
       * @description 위 메서드와 세트임.
       */
      const inputKeyValidate = (value: string) => {
        if (typeof onChangeValue === 'function') {
          if (typeof required !== 'undefined') {
            handleCheckValidate(value);
          }
          onChangeValue(value);
        }
      };

      const _style: CSSProperties = {
        width: rest.readOnly === true ? 'auto' : '100%',
        height: 50,
        padding: 5,
        border: 0,
        paddingLeft: 10,
        // textTransform: format === 'url' ? 'lowercase' : 'none',
        ...rest.style,
      };

      const _labelStyle: CSSProperties = { ...labelStyle, justifyContent: 'space-evenly' };

      useLayoutEffect(() => {
        if (
          typeof required !== 'undefined' &&
          typeof id !== 'undefined' &&
          rest.disabled !== true
        ) {
          formValidationStore.appendChildren(id);
        } else {
          if (typeof id !== 'undefined') {
            formValidationStore.removeChildren(id);
          }
        }
      }, [required, rest.disabled]);

      useEffect(() => {
        if (
          (typeof rest.defaultValue !== 'undefined' && rest.disabled === false) ||
          typeof rest.disabled === 'undefined'
        ) {
          handleCheckValidate(rest.defaultValue);
          if (typeof inputRef !== 'undefined' && typeof rest.defaultValue !== 'undefined') {
            inputRef.current.value = rest.defaultValue;
          } else {
            inputRef.current.value = '';
          }
        }
      }, [rest.defaultValue]);

      useEffect(() => {
        if (typeof rest.value !== 'undefined') {
          handleCheckValidate(rest.value);
        }
      }, [rest.value]);

      useEffect(() => {
        if (rest.disabled === false && typeof rest.value !== 'undefined') {
          handleCheckValidate(rest.value);
        }
      }, [rest.disabled]);

      return (
        <div style={{ ...boxStyle, flex: 1 }}>
          <div style={_labelStyle} className={`${typeof label !== 'undefined'} ? 'row' : ''`}>
            <div
              style={{
                alignItems: 'flex-start',
                display: 'flex',
                color: 'red',
                fontSize: 14,
                marginLeft: 10,
              }}
            >
              {typeof label !== 'undefined' && (
                <span
                  style={{
                    // color: validMessage === '' ? '#000' : 'red',
                    color: '#000',
                    fontWeight: 500,
                    marginBottom: 10,
                  }}
                >
                  {/* {validMessage === '' ? label : `${label}- `} */}
                  {label}
                </span>
              )}
              {messageLocation === 'top' && (
                <div style={{ height: 'auto', textAlign: 'left' }}> {validMessage}</div>
              )}
            </div>
          </div>
          <div style={_containerStyle}>
            <InputStyle
              {...rest}
              //ref={ref as ForwardedRef<HTMLInputElement>}
              ref={inputRef}
              style={_style}
              className="input"
              /**
               * 백스페이스 및 딜리트 키의 경우 onChange가 발생하지 않아 별도로 구현함.
               */
              onKeyUp={(e: any) => {
                if (e.key === 'Backspace' || e.key === 'Delete') {
                  inputKeyValidate(e.target.value);
                }
              }}
              onFocus={(e) => {
                isFieldValid.current = undefined;
              }}
              onChange={(e) => {
                inputKeyValidate(e.target.value);
              }}
              onBlur={(e: any) => {
                if (typeof id !== 'undefined') {
                  handleCheckValidate(e.target.value);
                }
                if (typeof onBlur !== 'undefined') {
                  onBlur(isFieldValid.current);
                }
              }}
            />
          </div>

          {messageLocation === 'bottom' && (
            <div style={{ textAlign: 'left', marginTop: 5, marginLeft: 10, color: 'red' }}>
              {validMessage}
            </div>
          )}
        </div>
      );
    },
  ),
);

const InputStyle = styled.input`
  &:disabled {
    opacity: 0.3;
  }
`;
export default React.memo(Input);
