import React from "react";
import BlueButton from "components/commons/BlueButton";
import styled, { css } from "styled-components";

const EditProfilePage = () => {
  return (
    <EditProfileContainer>
      <ProfileContainer>
        <ProfileImg src="image/profile.jpeg" width={100} />
        <Title>프로필 제목 입력</Title>
        <p>프로필 소개 내용 프로필 소개 내용 프로필 소개 내용 프로필 소개 내용 프로필 소개 내용 프로필 소개 내용</p>
      </ProfileContainer>
      <EditBtnContainer>
        <button>
          <Img src="image/user-octagon.png" />
          프로필 사진
        </button>
        <Bar>
          <div />
        </Bar>
        <button>
          <Img src="image/background.png" />
          배경 사진
        </button>
      </EditBtnContainer>
      <EditContainer>
        <>
          <TitleWrapper>
            <Title>프로필 제목</Title>
            <span>(0/20)</span>
          </TitleWrapper>
          <EditInput
            placeholder="프로필 제목"
            onChange={(e) => {
              console.log(e.target.value);
            }}
          ></EditInput>
        </>
        <>
          <TitleWrapper>
            <Title>프로필 소개</Title>
            <span>(0/100)</span>
          </TitleWrapper>
          <EditIntro
            placeholder="3줄까지 등록가능합니다."
            onChange={(e) => {
              console.log(e.target.value);
            }}
          ></EditIntro>
        </>
      </EditContainer>
      <BlueButton props={{ title: "등록하기" }} />
    </EditProfileContainer>
  );
};


const flexCenter = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  > span {
    font-size: 12px;
    color: #999999;
  }
`;

const Title = styled.p`
  font-weight: bold;
`

const Img = styled.img.attrs(props => ({
  alt: "",
}))<{width?:number}>`
  width: ${(props)=> props.width || "30px" };
  margin-bottom: 0.5em;
`

const ProfileImg = styled(Img)`
  border-radius: 50%;
`

const EditProfileContainer = styled.div`
  /* padding: 0 1em; */
  margin-top: 4em;
`;

const ProfileContainer = styled.div`
  ${flexCenter}
  text-align: center;
  background-image: url("/public/image/bgTest.png");
  background-size: cover;
  background: lightblue;
  padding: 1.5em;
  > p {
    padding: 0 3em;
    margin-top: 0;
  }
`;

const EditBtnContainer = styled.div`
  ${flexCenter}
  flex-direction: row;
  justify-content: space-around;
  background: #f6f6f6;
  width: 90%;
  margin: 1em auto;
  border-radius: 10px;
  > button {
    background: #f6f6f6;
    border: none;
    height: 7em;
    ${flexCenter}
    justify-content: center;
    font-size: 16px;
    cursor: pointer;
    padding: 3em;
  }
`;

const Bar = styled.div`
  background-color: #f6f6f6;
  height: 7em;
  object-fit: contain;
  width: 2px;
  display: flex;
  align-items: center;
  > div {
    background-color: #dddddd;
    height: 4.5em;
    object-fit: contain;
    width: 2px;
  }
`;

const EditContainer = styled.div`
padding: 0 1em;
`

const EditInput = styled.input`
  border: 1px solid #dddddd;
  border-radius: 3px;
  resize: none;
  width: 97%;
  padding-left: 1em;
  height: 2.5em;
`;

const EditIntro = styled(EditInput)`
//프로필소개부분 textarea안하고 input하니까 입력시 이상함,,,
  height: 10em;
  padding-top: 1em;
  margin-bottom: 4em;
  &::placeholder {
    position: absolute;
    top: 1em;
  }
`;

// const ProfileEdit = styled.div`
//   padding: 1em;
//   padding-bottom: 7em;
// `;

export default EditProfilePage;
