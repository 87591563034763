/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { CSSProperties, forwardRef, InputHTMLAttributes } from 'react';
import { validityCheck, validityCustomCheck } from 'controls/validationHelper';
import styled from 'styled-components';
interface InputProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  id?: string;
  label?: any;
  // type: 'text' | 'textarea';
  register?: unknown; // declare register props
  containerStyle?: CSSProperties;
  onChangeValue?: (value: string) => void;
}
/**
 * @description
 * @returns
 */
const TextArea = forwardRef(
  ({ label, type, onChangeValue, containerStyle, ...rest }: InputProps, ref) => {
    // eslint-disable-next-line no-underscore-dangle
    const _containerStyle: React.CSSProperties = {
      border: '1px solid #efefef',
      borderRadius: 5,
      textAlign: 'center',
      backgroundColor: '#fff',
      ...containerStyle,
    };

    const textareaStyle: CSSProperties = {
      fontFamily: 'Noto Sans KR, sans-serif',
      width: '95%',
      height: 200,
      backgroundColor: 'transparent',
      padding: 5,
      paddingRight: 0,
      border: 0,
      fontSize: 20,
      ...rest.style,
    };

    return (
      <>
        <TextAreaContainerStyle>
          {typeof label !== 'undefined' && <LabelStyle>{label}</LabelStyle>}
          <div style={_containerStyle}>
            <textarea
              {...rest}
              rows={5}
              defaultValue={rest?.defaultValue}
              style={textareaStyle}
              onChange={(e) => {
                if (typeof onChangeValue === 'function') {
                  onChangeValue(e.target.value);
                }
              }}
            />
          </div>
        </TextAreaContainerStyle>
      </>
    );
  },
);

const TextAreaContainerStyle = styled.div`
  width: 100%;
  text-align: left;
`;

const LabelStyle = styled.div`
  margin: 5px 0 5px 0;
`;

export default TextArea;
