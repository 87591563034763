import styled from "styled-components";

/**
 * @description
 */
const TermsPage = styled.div`
  background: white;
`;

export default TermsPage;
