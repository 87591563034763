import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import BasicButton from 'components/commons/BasicButton';

const MePage = () => {
  const { t } = useTranslation();
  const handleClickUserInfo = () => {
    //
  };

  return (
    <MobileSizeWrapper>
      <MainTitle>{t('myInformation')}</MainTitle>

      <Footer>
        <div style={{ paddingBottom: '16px' }}>{t('nbhdTitle')}</div>
        <Title>
          {t('nbhdDescriptionOne')}
          <br />
          {t('nbhdDescriptionTwo')}
          <br />
          {t('nbhdDescriptionThree')}
        </Title>
        <Title>
          {t('email')} : moss@ohmoss.com <br />
          {t('tel')} : 02-6949-8264 <br />
          {t('fax')} : 070-8282-9191
        </Title>
      </Footer>
    </MobileSizeWrapper>
  );
};

const MobileSizeWrapper = styled.div`
  @media ${(props) => props.theme.media.mobile} {
    padding: 16px;
    background-color: white;
  }
  @media ${(props) => props.theme.media.desk} {
    padding-top: 46px;
    margin-left: calc(50% - 300px);
    width: 600px;
  }
`;
const ButtonGroup = styled.div`
  margin-bottom: 32px;
`;

const MainTitle = styled.div`
  text-align: center;
  font-size: 50px;
  padding-bottom: 16px;
  @media ${(props) => props.theme.media.mobile} {
    display: none;
  }
`;

const Title = styled.div`
  color: #666666;
  padding-bottom: 16px;
`;

const Footer = styled.div`
  font-size: 12px;
  position: absolute;
  margin-top: 80px;
  margin-right: 16px;
`;

export default MePage;
