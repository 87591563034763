const convertToBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = (e: any) => {
      // resolve(fileReader.result);
      resolve(URL.createObjectURL(file));
      const tt = ';';
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export default convertToBase64;
