import { makeAutoObservable } from 'mobx';

type MessageTypes = {
  [index: string]: string;
  url: string;
};
class FormValidationStore {
  controls: Array<{ id: string; isValidForm?: boolean }> = [];
  isValidForm: boolean = false;
  messages: MessageTypes = { url: 'Url format is incorrect.', number: 'Number only.' };

  constructor() {
    makeAutoObservable(this);
  }

  appendChildren(id: string) {
    const filter = this.controls.filter(
      (control: { id: string; isValidForm?: boolean }) => control.id === id,
    );

    if (filter.length === 0) {
      //required 선언된것들만 대상으로 함.
      this.controls.push({ id: id, isValidForm: false });
    }
  }

  removeChildren(id: string) {
    const filter = this.controls.findIndex((control: any) => control.id === id);
    if (filter >= 0) {
      var removed = this.controls.splice(filter, 1);
    }
  }

  handleCheckValid(id: string, value: boolean) {
    const filter = this.controls.filter(
      (control: { id: string; isValidForm?: boolean }) => control.id === id,
    );
    if (filter.length > 0) {
      filter[0].isValidForm = value;
    }
    this.checkValidControls();
  }

  checkValidControls() {
    if (this.controls.length === 0) {
      return false;
    }
    const filter2 = this.controls.filter(
      (control: { id: string; isValidForm?: boolean }) => control.isValidForm === false,
    );

    /** 부모에게 전체 컨트롤 세트의 검사 결과를 반환한다. */
    this.isValidForm = filter2.length === 0;
  }

  initialize() {
    this.checkValidControls();
  }

  destroy() {
    this.controls = [];
    this.isValidForm = false;
  }
}

export default FormValidationStore;
