import React from 'react';
import { useTranslation } from 'react-i18next';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import MenuItem from '@mui/material/MenuItem';
import List from '@mui/material/List';
import Box from '@mui/material/Box';

interface MenuItems {
  title: string;
  onClick?: () => void;
}

interface DrawerProps {
  open: boolean;
  menuList: MenuItems[];
  languageOpen?: boolean;
  handleClickOpen?: any;
  handleClose: () => void;
}

const MobileDrawer = ({
  open,
  menuList,
  languageOpen,
  handleClickOpen,
  handleClose,
}: DrawerProps) => {
  const { i18n } = useTranslation();

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    if (open === false) {
      return handleClose();
    }
  };

  const languageList = [
    { title: 'ko' },
    { title: 'en' },
    { title: 'jp' },
    { title: 'cn' },
    { title: 'sp' },
  ];

  const handleClickChangeLanguage = (target: string) => {
    i18n.changeLanguage(target);
  };

  return (
    <SwipeableDrawer
      anchor={'top'}
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
    >
      <Box sx={{ width: 'auto' }} role="presentation">
        {languageOpen === true ? (
          <List>
            {languageList?.map((item: MenuItems, i: number) => (
              <MenuItem
                key={i}
                onClick={() => {
                  handleClickChangeLanguage(item.title);
                  handleClose();
                }}
                sx={{
                  justifyContent: 'space-evenly',
                  borderTop: `${i === 0 ? '' : '2px solid rgb(229, 229, 229, 0.895)'}`,
                  height: '56px',
                  fontWeight: '600',
                }}
              >
                {item.title}
              </MenuItem>
            ))}
          </List>
        ) : (
          <List>
            {menuList?.map((item: MenuItems, i: number) => (
              <MenuItem
                key={i}
                onClick={item.onClick}
                sx={{
                  justifyContent: 'space-evenly',
                  borderTop: `${i === 0 ? '' : '2px solid rgb(229, 229, 229, 0.895)'}`,
                  height: '56px',
                  fontWeight: '600',
                }}
              >
                {item.title}
              </MenuItem>
            ))}
          </List>
        )}
      </Box>
    </SwipeableDrawer>
  );
};

export default MobileDrawer;
