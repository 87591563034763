import _ from 'lodash';
import { useObserver } from 'mobx-react';
import { useLayoutEffect } from 'react';
import FormValidationStore from 'stores/FormValidationStore';

const useForm = (props: FormValidationStore) => {
  useLayoutEffect(() => {
    props.initialize();
    return () => {
      props.destroy();
    };
  }, []);

  return useObserver(() => ({
    result: props.isValidForm,
  }));
};

export default useForm;
