import styled from 'styled-components';
import React from 'react';

type ButtonProps = {
  props: {
    title: string;
  };
};

const BlueButton = ({ props }: ButtonProps) => {
  const { title } = props;

  const handleClick = () => {
    console.log('clicked');
  };

  return (
    <>
      <BlueBtnStyle type="button" onClick={handleClick}>
        {title}
      </BlueBtnStyle>
    </>
  );
};

const BlueBtnStyle = styled.button`
  /* margin-top: 2em; */
  width: 100%;
  background: #3e64ea;
  font-size: 16px;
  box-shadow: none;
  color: white;
  overflow: hidden;
  cursor: pointer;
  height: 4em;
  position: relative;
  bottom: 0;
  border: none;
`;

export default BlueButton;
