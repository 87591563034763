import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';

interface InputDto {
  label: string;
  subLabel?: string;
  type?: string;
  placeholder?: string;
  defaultValue?: string | undefined;
  maxLength?: number;
  required?: boolean;
  validation?: string; // 타입 정의 후 사용 할 것
  onChange?: (itme?: any | undefined) => void | undefined;
}

const AuthInput = ({
  label,
  subLabel,
  type,
  placeholder,
  defaultValue,
  maxLength,
  onChange,
  required,
  validation,
}: InputDto) => {
  //
  return (
    <InputWrapper>
      <InputLabel>
        {label} <InputSubLabel>{!_.isUndefined(subLabel) && subLabel}</InputSubLabel>
      </InputLabel>
      <InputComponent
        defaultValue={!_.isUndefined(defaultValue) ? defaultValue : ''}
        type={!_.isUndefined(type) ? type : 'text'}
        placeholder={!_.isUndefined(placeholder) ? placeholder : ''}
        maxLength={!_.isUndefined(maxLength) ? maxLength : 524288}
        onChange={(e) => {
          !_.isUndefined(onChange) && onChange(e.target.value);
        }}
      />
      <InvalidMessage>{}</InvalidMessage>
    </InputWrapper>
  );
};

const InputWrapper = styled.div`
  padding-bottom: 16px;
`;

const InputLabel = styled.div`
  display: flex;
  padding-bottom: 16px;
`;
const InputSubLabel = styled.div`
  padding-left: 8px;
  font-weight: 300;
`;
const InputComponent = styled.input`
  height: 48px;
  border: 1px solid #dddddd;
  border-radius: 8px;
  width: 100%;
  padding-left: 16px;
  font-size: 16px;
  @media ${(props) => props.theme.media.mobile} {
    width: calc(100% - 1.2em);
  }
  @media ${(props) => props.theme.media.desk} {
    width: 100%;
  }
`;
const InvalidMessage = styled.div`
  color: #f92457;
  font-size: 14px;
  padding-top: 8px;
`;

export default AuthInput;
