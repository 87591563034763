import React from 'react';
import UseTermsContainer from 'features/mainFeatures/terms/containers/UseTermsContainer';
import ServiceTerms from 'features/mainFeatures/terms/containers/ServiceTerms';
import TermsPage from 'features/mainFeatures/terms/components/TermsPage';

const ServiceTermsPage = () => {
  return (
    <TermsPage>
      <UseTermsContainer />
      <ServiceTerms />
    </TermsPage>
  );
};

export default ServiceTermsPage;
