import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';

const MobileModal = ({
  isShow,
  height = '300',
  onClose,
  children,
}: {
  isShow: boolean;
  height?: string;
  onClose: () => void;
  children: React.ReactElement;
}) => {
  const { t } = useTranslation();
  const [modalShow, setModalShow] = useState<boolean>(false);

  useEffect(() => {
    setModalShow(isShow);
  }, [isShow]);
  return (
    <Modal
      isOpen={modalShow}
      onRequestClose={() => {
        setModalShow(false);
        onClose();
      }}
      ariaHideApp={false}
      style={{
        overlay: { backgroundColor: 'rgba(0, 0, 0, 0.75)', zIndex: 0 },
        content: {
          marginTop: 60,
          paddingTop: 30,
          height: `calc( ${height} - 90px)`,
          margin: 'auto',
          inset: '0px',
        },
      }}
    >
      {children}
      <div style={{ position: 'absolute', bottom: 40, left: 20, right: 20 }}>
        <button
          className="button default"
          onMouseDown={() => {
            onClose();
            setModalShow(false);
          }}
        >
          {t('close')}
        </button>
      </div>
    </Modal>
  );
};
export default React.memo(MobileModal);
