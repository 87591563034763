import styled from 'styled-components';

/**
 * @description 로그인, 회원가입 페이지 버튼
 */
const BlueBtn = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  cursor: pointer;
  margin: 1em auto;
  height: 60px;
  color: #fff;
  border-radius: 30px;
  width: 120px;
  font-size: 18px;
  font-weight: 500;
  background: #3e64ea;
  &:hover {
    opacity: 0.4;
  }
`;

export default BlueBtn;
